@import '../../colors.less';

.beans-navigation {
  display: grid;
  height: 100%;
  width: min-content;
  grid-template-columns: 64px auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'menu submenu'
    '. submenu'
    'footer submenu';
  border-right: 1px solid #e6e6e6;
  position: relative;
  overflow-y: auto;
  box-shadow: rgb(35 41 54 / 4%) 0px 4px 12px 0px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.beans-navigation--menu {
  grid-area: menu;

  & .beans-navigation--tile-selected:first-child {
    border-top-color: transparent;
  }
}

.beans-navigation--footer {
  grid-area: footer;
  border-top: 1px solid #e6e6e6;
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: @white;
  z-index: 1;

  & .beans-navigation--tile-selected:first-child {
    border-top-color: transparent;
  }
}

.beans-navigation--submenu {
  grid-area: submenu;
  width: 220px;
  max-width: 220px;
  height: 100%;
  background-color: @gray-100;
  border-left: 1px solid #e6e6e6;
  box-sizing: border-box;
  position: relative;

  & > .beans-navigation--submenu-inner {
    height: 100vh;
    padding: 18px 10px;
    position: sticky;
    top: 0;
    right: 0;
    overflow: auto;
  }

  &.beans-navigation--submenu-hide {
    max-width: 0px;
    overflow: hidden;
    border: none;
    transition: max-width 0.1s ease-in;
  }
}
