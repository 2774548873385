@import '../../../colors.less';

.beans-navigation--tile {
  width: 64px;
  height: 64px;
  padding: 14px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  &::after {
    position: absolute;
    content: '';
    height: 63px;
    width: 1px;
    background-color: #e6e6e6;
    right: -1px;
    top: 0px;
  }

  // image
  &.beans-navigation--tile-image > .beans-navigation--image {
    background-color: @white !important;
    border: 1px solid #e6e6e6 !important;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // svg
  &.beans-navigation--tile-icon > .beans-navigation--tile-icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    & svg {
      margin-bottom: 6px;
      font-size: 18px;
    }

    & * {
      color: @gray-800;
      fill: @gray-800;
    }
  }

  // hover
  &:hover {
    background-color: @gray-100;
  }

  // focus
  .beans-navigation--tile-i {
    display: none;
  }

  &:focus .beans-navigation--tile-i {
    display: block;
    position: absolute;
    left: 2px;
    top: 2px;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid @beans-primary-color;
  }

  &.beans-navigation--tile-selected.beans-navigation--tile-image:focus .beans-navigation--tile-i {
    left: 4px;
    width: 58px;
  }

  // selected
  &.beans-navigation--tile-selected {
    background-color: @gray-100;
    border-top-color: #e6e6e6;

    &::after {
      background-color: @gray-100;
    }
  }

  // selected image
  &.beans-navigation--tile-image.beans-navigation--tile-selected {
    &::before {
      content: '';
      position: absolute;
      left: -1px;
      top: -0.5px;
      height: 64px;
      border-left: 4px solid @beans-primary-color;
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;
    }
  }

  // selected svg
  &.beans-navigation--tile-icon.beans-navigation--tile-selected {
    & * {
      color: @beans-primary-color;
      fill: @beans-primary-color;
    }
  }

  &.beans-navigation--tile-logo {
    pointer-events: none;

    // image
    &.beans-navigation--tile-image > .beans-navigation--image {
      border: transparent !important;
      border-radius: 0px;
    }

    &:hover {
      background-color: inherit;
    }
  }
}

.beans-navigation--tile-icon-title {
  line-height: 9px;
  font-size: 8px;
  text-align: center;
  word-break: keep-all;
}

.beans-navigation--tooltip {
  .ant-tooltip-arrow-content::before {
    background: @white;
  }

  &:not(.ant-tooltip-hidden) {
    left: 60px !important;
  }

  .ant-tooltip-arrow-content {
    transform: translateX(15px) rotate(135deg);
  }

  .ant-tooltip-inner {
    background-color: @white;
    border-radius: 3px;
    box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.25);
    color: @black;
    height: 26px;
    min-height: auto;
    font-size: 14px;
    line-height: 26px;
    padding: 0px 8px;
  }
}
