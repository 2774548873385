@import '../../colors.less';

.beans-layout--main {
  display: grid;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'nav header'
    'nav content'
    'nav footer';
}

.beans-layout--main-nav {
  position: sticky;
  top: 0;
  grid-area: nav;
  background: @white;
  height: 100vh;
  width: auto;
}

.beans-layout--main-header {
  top: 0;
  grid-area: header;
  background: @white;
  height: 80px;
  padding: 20px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 1;
}

.beans-layout--main-footer {
  grid-area: footer;
  background: @white;
  height: 77px;
}

.beans-layout--main-content {
  padding: 25px 24px;
  grid-area: content;
  background: @gray-200;
}
