@import '../../shadows.less';

.beans-card-title-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 64px;
}

.beans-card-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
}

.beans-card {
  box-shadow: @beans-card-shadow;
  border-radius: 4px;

  &:not(.beans-card-with-footer) .ant-card-body {
    transition: max-height 0.3s linear;
    transition: padding 0.3s ease-out;
    max-height: max-content;
  }

  .ant-card-head {
    padding: 0px 16px;
  }

  .ant-card-head-title {
    padding: 0px;
    display: flex;
    align-items: center;
  }

  // tabs
  .ant-tabs > .ant-tabs-nav {
    height: 62px;

    &::before {
      border-bottom: 1px solid #f4f4f4;
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-tab {
    align-items: flex-end;
    padding: 10px 0px;
  }

  &.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
    padding-bottom: 16px;
  }
}

// with footer
.beans-card.beans-card-with-footer {
  .ant-card-body {
    padding: 0px;
  }

  .beans-card-footer-body {
    padding: 24px;
    max-height: max-content;
    transition: max-height 0.3s linear;
    transition: padding 0.3s ease-out;
  }

  .beans-card-footer {
    padding: 16px 12px;
  }

  // small card with footer
  &.ant-card-small {
    .beans-card-footer-body {
      padding: 12px;
    }

    .beans-card-footer {
      padding: 12px 8px;
    }
  }

  // card loading with footer
  &.ant-card-loading {
    .ant-card-body {
      padding: 24px;
    }

    // small card loading
    &.ant-card-small .ant-card-body {
      padding: 12px;
    }
  }
}

// small card
.beans-card.ant-card-small {
  .ant-card-head {
    padding: 0px 8px;
  }

  .beans-card-title-wrapper {
    height: 32px;
  }

  .beans-card-title {
    font-size: 16px;
    line-height: 20px;
  }

  &.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
    padding-bottom: 8px;
  }
}

// collapsed card
.beans-card.beans-card-collapsed {
  .ant-card-head {
    transition: border-bottom-color 0.3s ease-in-out;
    border-bottom-color: transparent;
  }

  &:not(.beans-card-with-footer) .ant-card-body,
  &.beans-card-with-footer .beans-card-footer-body {
    max-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
  }
}

/* ------------------------------- Shadow Off ------------------------------- */
.beans-card-shadow-off {
  box-shadow: none;
}
