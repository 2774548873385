.beans-pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .beans-pagination-select-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .beans-pagination-select {
    min-width: 100px;
  }

  .beans-pagination-btn-icon {
    font-size: 10px;
  }

  .beans-pagination-next-btn {
    margin-left: 12px;
  }
}

.beans-pagination-select-dropdown.beans-pagination-select-dropdown.ant-select-dropdown {
  width: min-content !important;
}
