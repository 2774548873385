@import '../../colors.less';

.beans-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 24px;
  line-height: 20px;
  padding: 0px 8px;
  margin: 0px;
  border: none;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
}

.beans-tag-border {
  border: 1px solid;
}

.beans-tag-bold {
  font-weight: bold;
}

/* --------------------------------- Colors --------------------------------- */

.beans-tag {
  &.beans-tag-color-none {
    background-color: @gray-100;
    border-color: #e6e6e6;
    color: @black;
  }

  &.ant-tag-green {
    background-color: @green-50;
    border-color: #7aad34;
    color: #7aad34;
  }

  &.ant-tag-volcano {
    background-color: #ffe9ea;
    border-color: @red-500;
    color: @red-500;
  }
}
