@import '../../colors.less';

/* -------------------------- Default Input Number -------------------------- */

.beans-input-number {
  width: 100%;
  border-radius: 2px;
  border-color: #e6e6e6;

  &:not(.ant-input-number-affix-wrapper):hover:not(.ant-input-number-focused):not(.ant-input-number-disabled) {
    border-color: @gray-500;
  }

  input::placeholder {
    color: @gray-500;
  }
}

// foucs
.beans-input-number.ant-input-number-focused {
  box-shadow: none;
  border-color: @beans-primary-color;
}

// diasbled
.beans-input-number.ant-input-number-disabled {
  background-color: @white;
  border-color: #e6e6e6;
  opacity: 0.4;
}

// error
.beans-input-number.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless) {
  background-color: #fff5f5;
  color: @red-500;
  border-color: @red-500;

  &:hover {
    background-color: #fff5f5;
    border-color: @red-500;
  }

  &.beans-input-number.ant-input-number-focused {
    box-shadow: none;
    background-color: @white;
  }

  .ant-input-number-handler-wrap .ant-input-number-handler svg {
    fill: @red-500;
  }
}

// arrow buttons
.beans-input-number:not(.beans-input-number-hidden-controls) .ant-input-number-handler-wrap {
  opacity: 1;
}

.beans-input-number .ant-input-number-handler-wrap {
  width: 37px;
  background: @gray-100;

  .ant-input-number-handler {
    width: 100%;
    border: none !important;

    &:hover {
      background-color: @gray-200;
    }

    &:active {
      background-color: #d6eeff78;
    }

    svg {
      fill: rgba(0, 0, 0, 0.25);
    }

    &.ant-input-number-handler-down-disabled,
    &.ant-input-number-handler-down-disabled {
      opacity: 0.4;

      &:active,
      &:hover {
        background: @gray-100;
      }
    }
  }

  .ant-input-number-handler .ant-input-number-handler-up-inner,
  .ant-input-number-handler .ant-input-number-handler-down-inner {
    font-size: 10px;
    left: 50%;
  }

  .ant-input-number-handler .ant-input-number-handler-up-inner {
    transform: translateX(-50%);
  }

  .ant-input-number-handler .ant-input-number-handler-down-inner {
    transform: translate(-50%, -50%);
  }
}

/* ------------------------ Input Number with Prefix ------------------------ */

// hover
.beans-input-number.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-focused):not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: @gray-500;
}

// focus
.beans-input-number.ant-input-number-affix-wrapper.ant-input-number-affix-wrapper-focused {
  box-shadow: none;
  border-color: @beans-primary-color;

  &:not(.ant-input-number-affix-wrapper-disabled):hover {
    border-color: @beans-primary-color;
  }
}

// disabled
.beans-input-number.ant-input-number-affix-wrapper.ant-input-number-affix-wrapper-disabled {
  background-color: @white;
  border-color: #e6e6e6;
  opacity: 0.4;
}

// error
.beans-input-number.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.beans-input-number.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background-color: #fff5f5;
  border-color: @red-500;
  box-shadow: none;

  &.ant-input-number-affix-wrapper-focused {
    background-color: @white;
  }

  & svg {
    fill: @red-500;
  }
}

.beans-input-number.ant-input-number-affix-wrapper.ant-input-number-affix-wrapper-disabled.ant-input-number-affix-wrapper-status-error
  .ant-input-number-prefix {
  color: rgba(0, 0, 0, 0.25);
}

/* ------------------------- Input Number with Addon ------------------------ */

.beans-input-number.ant-input-number-group-wrapper:has(.beans-input-number) {
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  .ant-input-number-group-addon {
    background-color: @gray-100;
    border-color: @gray-100;
  }

  .beans-input-number {
    border: none;
  }

  // disabled
  &:has(.beans-input-number.ant-input-number-disabled) {
    border-color: #e6e6e6;
    opacity: 0.4;

    .beans-input-number.ant-input-number-disabled {
      opacity: 1;
    }

    &:hover {
      border-color: #e6e6e6;
    }
  }

  // focus
  &:has(.beans-input-number.beans-input-number.ant-input-number-focused:not(.beans-input-number.ant-input-number-disabled):not(.ant-input-number-status-error)) {
    border-color: @beans-primary-color;
  }

  // error
  &:has(.beans-input-number.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless)),
  &:has(.beans-input-number.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless)):hover,
  &:has(.beans-input-number.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless)) {
    border-color: @red-500;
  }
}

/* -------------------------- Select Addon Support -------------------------- */

.beans-input-number.ant-input-number-group-wrapper:has(.ant-select) {
  .ant-input-number-group-addon {
    border: none;
  }

  .ant-select .ant-select-selector {
    border: none;
  }
}
