body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout-header.ant-layout-header {
  height: auto;
  margin-bottom: 1em;
  padding: 0;
}

.ant-layout-content > .ant-card,
.ant-layout-content > .ant-list-grid {
  margin: 0 24px 16px;
}

/* put card meta title and description closer together */
.ant-list-item .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0;
}

/* make columns in list grid flexible */
.ant-list-grid .ant-col {
  display: flex;
}

/* remove default margin which makes images in bordered card covers overlap with border */
.ant-card-bordered > .ant-card-cover {
  margin-left: 0;
  margin-right: 0;
}

/* disable text selection styles */
.ant-menu *::selection {
  background: inherit;
  color: inherit;
}

/* show special styles for clickable rows */
.ant-table-row-clickable {
  cursor: pointer;
}

/* custom font-size for all tables */
.ant-table-cell {
  font-size: 13px;
}

/* lighter background for pictures preview overlay */
.ant-image-preview-img-wrapper {
  background-color: rgba(255, 255, 255, 0.85);
}

/* lock body scroll */
.ant-scrolling-effect {
  overflow: hidden;
  position: relative;
  width: calc(100% - 15px);
}

/* override Stripe card input styles */
.card-input {
  font-size: 1em !important;
  line-height: 1em !important;
  padding-top: 0.75em !important;
  padding-bottom: 0.75em !important;
  box-sizing: border-box !important;
}

/* reset right margin on pageheader (eg. login screen) */
.pageheader-no-right-margin span {
  margin-right: 0;
}

/* store product page - color & size picker have border radius - workaround antd's issues */
.store-product-color-picker.ant-radio-button-wrapper,
.store-product-size-picker.ant-radio-button-wrapper {
  border-left-width: 1px;
}

/* new layout for pre-login two-column page design - testimonial carousel "dots" are to be below testimonials on the left */
/* this is to nullify default styles */
.pre-login-two-column-testimonials-carousel .ant-carousel .slick-dots {
  display: block !important;
  position: absolute;

  left: 0;
  top: calc(100% + 32px);

  margin-left: 0;
}

@gray-9: #262626;

.drawer-subtitle {
  color: @gray-9;
  font-weight: normal;
  margin-bottom: 24px;
}

.subcart-items-table {
  .ant-table-cell {
    background: transparent;
    font-weight: normal;
  }
}

.ant-btn.ant-btn-padding-top {
  padding-top: 4px !important;
}

.summary-card .ant-descriptions-header {
  margin-bottom: 12px !important;
}

/* CustomSkeleton */
.skeleton {
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  inset: 0 -150%;
  animation: ant-skeleton-loading 1.4s ease infinite;
  position: absolute;
}

.skeletonBox {
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  height: 100%;
  width: 100%;
  background: 0 0;
}

.noBodyTablePadding .ant-card-body {
  padding: 0px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-0 {
  margin-bottom: 0px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.gap-8 {
  gap: 8px;
}

.flex-grow {
  flex-grow: 1;
}

.bold {
  font-weight: bold;
}

.wfull {
  width: 100%;
}

