@import '../../colors.less';
@import '../../shadows.less';

/* ------------------------ Default Select Component ------------------------ */

.beans-select-icon {
  color: @black;
  font-size: 8px;
}

.beans-select.ant-select {
  .ant-select-selector {
    border-color: #e6e6e6;
    border-radius: 2px;
  }

  &::placeholder {
    color: @gray-500;
  }

  .anticon-close-circle {
    color: @red-500;
  }

  .ant-select-selection-overflow-item {
    margin-right: 4px;

    .ant-select-selection-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      height: 24px;
      line-height: 20px;
      padding: 0px 8px;
      margin: 0px;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      background-color: #f7fcff;
      color: @black;
      border: 1px solid @beans-primary-color;
    }
  }
}

// disabled
.beans-select.ant-select-disabled:not(.ant-select-customize-input),
.beans-select.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
  opacity: 0.4;

  .ant-select-selector {
    background: @white;
  }

  .ant-select-selection-overflow-item .ant-select-selection-item {
    background-color: @gray-100;
    border-color: #e6e6e6;
  }
}

// hover
.beans-select.ant-select:not(.ant-select-disabled):not(.ant-select-focused):hover {
  .ant-select-selector {
    border-color: @gray-500;
  }
}

// focus
.beans-select.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) {
  .ant-select-selector {
    box-shadow: none;
    border-color: @beans-primary-color;
  }
}

// error
.beans-select.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
  .ant-select-selector {
    box-shadow: none;
    color: @red-500;
    border-color: @red-500;
    background-color: #fff5f5;
  }

  &.beans-select.ant-select-focused .ant-select-selector {
    background-color: @white;
  }

  .ant-select-selection-overflow-item .ant-select-selection-item {
    border-color: @red-500;
    background-color: #fff5f5;
  }

  svg {
    fill: @red-500;
  }
}

/* ----------------------------- Select Dropdown ---------------------------- */

.beans-select-dropdown.ant-select-dropdown {
  box-shadow: @beans-card-shadow;
  border-radius: 4px;
  padding: 8px;

  .ant-select-item {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid transparent;
  }

  .ant-select-item-option-selected {
    background-color: #f7fcff;
    border-color: @beans-primary-color;

    &:has(+ .ant-select-item-option-selected) {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-color: #f7fcff;

      & + .ant-select-item-option-selected {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top-color: #f7fcff;
      }
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-selected) {
    background-color: #f1f1f1;
  }
}
