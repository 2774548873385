@import '../../colors.less';

@btn-square-only-icon-size: 16px;

.ant-btn {
  text-shadow: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  // icon style
  & > .anticon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: @btn-square-only-icon-size;
  }

  &.ant-btn-lg > .anticon {
    font-size: @btn-square-only-icon-size + 2px;
  }

  &.ant-btn-sm > .anticon {
    font-size: @btn-square-only-icon-size - 2px;
  }

  // disable
  &:disabled {
    opacity: 0.4;
  }

  // prefix icon
  &.beans-button-prefix {
    & > .beans-button-number::after {
      content: '';
      border-right: 1px solid @white;
      opacity: 0.2;
      margin: 0px @margin-xs;
    }
  }

  // suffix icon
  &.beans-button-suffix {
    flex-direction: row-reverse;

    & > .anticon {
      margin-right: 0px;
      margin-left: @margin-xs;
    }

    & > span {
      margin-left: 0px;
    }

    & > .beans-button-number::before {
      content: '';
      border-right: 1px solid @white;
      opacity: 0.2;
      margin: 0px @margin-xs;
    }
  }
}

a.ant-btn {
  padding: 0px 15px !important;
}

html[theme='beans'] {
  .ant-btn-primary {
    // default
    background-color: @beans-primary-color;

    // hover
    &:enabled:hover {
      background-color: #0d65cc;
      border-color: #0d65cc;
    }

    // focus
    &:enabled:focus {
      background-color: @beans-primary-color;
      border-color: @blue-400;
    }

    // selected
    &:enabled:active {
      background-color: #024e85;
      border-color: #024e85;
    }

    // disabled
    &:disabled {
      border-color: @beans-primary-color;
      color: @white;
    }

    &:disabled:hover {
      background-color: @beans-primary-color;
    }

    // invalid
    &.ant-btn-dangerous {
      border-color: @red-500;
    }
  }

  .beans-button--secondary {
    // default
    background-color: @gray-100;
    border-color: #e6e6e6;
    color: @black;

    // hover
    &:enabled:hover {
      background-color: @gray-200;
      border-color: @gray-200;
    }

    // focus
    &:enabled:focus {
      border-color: @beans-primary-color;
    }

    // selected
    &:enabled:active {
      background-color: #e6e6e6;
      border-color: #e6e6e6;
    }

    &:disabled:hover {
      background-color: @gray-100;
      color: @black;
    }

    // invalid
    &:not(:focus):not(:hover).ant-btn-dangerous {
      border-color: @red-500;
      color: @red-500;
    }

    // preffix and suffix divider
    & > .beans-button-number::after {
      border-color: @black;
    }
  }

  .ant-btn-ghost {
    // default
    background-color: transparent;
    border-color: transparent;
    color: @beans-primary-color;

    & > .anticon {
      color: @gray-700;
    }

    // hover
    &:enabled:hover {
      background-color: @gray-200;
      border-color: @gray-200;
      color: @black;

      & > .anticon {
        color: @black;
      }
    }

    // focus
    &:enabled:focus {
      border-color: @beans-primary-color;

      & > .anticon {
        color: @black;
      }
    }

    // selected
    &:enabled:active {
      background-color: @gray-200;
      border-color: @gray-200;
      color: @beans-primary-color;

      & > .anticon {
        color: @beans-primary-color;
      }
    }

    &:disabled:hover {
      background-color: transparent;
      border-color: transparent;
      color: @beans-primary-color;
    }

    // invalid
    &:not(:focus):not(:hover).ant-btn-dangerous {
      border-color: @red-500;
      color: @red-500;

      & > .anticon {
        color: @red-500;
      }
    }

    // preffix and suffix divider
    & > .beans-button-number::after {
      border-color: @black;
    }
  }
}
