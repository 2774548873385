@import '../../colors.less';

.ant-input {
  border-radius: 2px;

  &:focus {
    box-shadow: none;
  }

  // disabled
  &.ant-input-disabled {
    background-color: @white;
    border-color: inherit;
    opacity: 0.4;
  }

  // error
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    &:focus {
      box-shadow: none;
    }
  }
}

// password
.ant-input-affix-wrapper.ant-input-password {
  // focus
  &.ant-input-affix-wrapper-focused.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  // disabled
  &.ant-input-affix-wrapper-disabled {
    opacity: 0.4;
  }
}

// addon
.ant-input-group-wrapper {
  // input with addon
  .ant-input {
    &:focus {
      box-shadow: none;
    }

    &.ant-input-disabled {
      background-color: @white;
      opacity: 0.4;
    }
  }

  // addon after
  .ant-input:has(+ .ant-input-group-addon) {
    border-right: none;

    &.ant-input-disabled + .ant-input-group-addon {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  // addon before
  .ant-input-group-addon {
    & + .ant-input {
      border-left: none;
    }

    // disabled
    &:has(+ .ant-input:disabled) {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  // search
  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: 0;
  }
}

// affix
.ant-input-affix-wrapper {
  border-radius: 2px;

  // focus
  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  // disabled
  &.ant-input-affix-wrapper-disabled {
    background-color: @white;
    border-color: inherit;
    opacity: 0.4;
  }

  // error
  &.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-disabled .ant-input-prefix {
    color: inherit;
  }

  &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
}

html[theme='beans'] {
  .ant-input {
    border-color: #e6e6e6;

    &::placeholder {
      color: @gray-500;
    }

    // focus
    &:focus:not(:disabled) {
      border-color: @beans-primary-color;
    }

    // hover
    &:hover:not(:focus):not(:disabled) {
      border-color: @gray-500;
    }

    // error
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
      background-color: #fff5f5;
      color: @red-500;
      border-color: @red-500;

      &:focus {
        background-color: @white;
      }
    }
  }

  // affix
  .ant-input-affix-wrapper {
    border-color: #e6e6e6;

    // hover
    &:hover {
      border-color: @gray-500;
    }

    // focus
    &.ant-input-affix-wrapper-focused {
      border-color: @beans-primary-color;
    }

    // error
    &.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless) {
      background-color: #fff5f5;
      border-color: @red-500;

      .ant-input {
        color: @red-500;
        background-color: #fff5f5;
      }

      // icon
      .ant-input-prefix {
        color: @red-500;
      }

      // focus
      &.ant-input-affix-wrapper-focused {
        background-color: @white;

        .ant-input {
          background-color: @white;
        }
      }
    }
  }

  // addon
  .ant-input-group-wrapper {
    // base
    .ant-input-group-addon {
      background-color: @gray-100;
      border-color: #e6e6e6;
    }

    //after addon
    .ant-input:has(+ .ant-input-group-addon) {
      // hover
      &:hover:not(:focus):not(:disabled) + .ant-input-group-addon {
        border-color: @gray-500;
      }

      // focus
      &:focus:not(:disabled) + .ant-input-group-addon {
        border-color: @beans-primary-color;
      }

      // error
      &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) + .ant-input-group-addon {
        border-color: @red-500;
      }
    }

    // before addon
    .ant-input-group-addon {
      // hover
      &:has(+ .ant-input:hover:not(:focus):not(:disabled):not(.ant-input-status-error)) {
        border-color: @gray-500;
      }

      // focus
      &:has(+ .ant-input:focus:not(:disabled)) {
        border-color: @beans-primary-color;
      }

      // error
      &:has(+ .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless)) {
        border-color: @red-500;
      }
    }
  }

  // search
  .ant-input-group-wrapper.ant-input-search .ant-input {
    /* default button */
    // hover
    &:hover + .ant-input-group-addon > .ant-input-search-button {
      border-color: @gray-500;
    }

    // focus
    &:focus + .ant-input-group-addon > .ant-input-search-button {
      border-color: @beans-primary-color;
    }

    // error
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless)
      + .ant-input-group-addon
      > .ant-input-search-button {
      border-color: @red-500;
    }

    /* primary button */
    // one svg child
    & + .ant-input-group-addon .ant-btn:has(> .anticon:only-child) {
      width: 32px;

      &.ant-btn-lg {
        width: 40px;
      }

      &.ant-btn-sm {
        width: 24px;
      }
    }

    // hover
    &:hover + .ant-input-group-addon > .ant-btn-primary {
      border-color: @beans-primary-color;
    }

    // focus
    &:focus + .ant-input-group-addon > .ant-btn-primary {
      border-color: @beans-primary-color;
    }

    // error
    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless)
      + .ant-input-group-addon
      > .ant-btn-primary {
      border-color: @red-500;
    }
  }

  // password
  .ant-input-affix-wrapper.ant-input-password {
    border-color: #e6e6e6;

    // hover
    &:hover {
      border-color: @gray-500;
    }

    // focus
    &.ant-input-affix-wrapper-focused {
      border-color: @beans-primary-color;
    }

    // disabled
    &.ant-input-affix-wrapper-disabled {
      background-color: @white;
      border-color: inherit;

      & .ant-input-suffix > span {
        color: #e6e6e6;
        cursor: not-allowed;
      }
    }

    // error
    &.ant-input-affix-wrapper-status-error {
      background-color: #fff5f5;
      border-color: @red-500;

      & > .ant-input {
        background-color: #fff5f5;
        color: @red-500;
      }

      // error hover
      &:hover {
        background-color: #fff5f5;
      }

      // error focus
      &.ant-input-affix-wrapper-focused {
        background-color: @white;

        & > .ant-input {
          background-color: @white;
        }
      }
    }
  }
}
