// addon
@import '~react-phone-input-2/lib/style.css';

// variables
@import '~antd/dist/antd.variable.less';
@import './colors.less';

// components
@import './components/Button/Button.less';
@import './components/Input/Input.less';
@import './layout/main/main.less';
@import './components/Navigation/Navigation.less';
@import './components/Navigation/Tile/Tile.less';
@import './components/Card/Card.less';
@import './components/Table/Table.less';
@import './components/SimpleDropdown/SimpleDropdown.less';
@import './components/Pagination/Pagination.less';
@import './components/PhoneInput/PhoneInput.less';
@import './components/Tag/Tag.less';
@import './components/Select/Select.less';
@import './components/InputNumber/InputNumber.less';
@import './components/Upload/Upload.less';

/* -------------------------------- Variables ------------------------------- */

@table-border-color: #f4f4f4;
@menu-icon-size: 21px;
@menu-icon-size-lg: 24px;

/* ------------------------------- Typography ------------------------------- */
.ant-typography {
  color: @black;
}

/* --------------------------------- Drawer --------------------------------- */
.ant-drawer-body {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 8px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
}
.ant-drawer-title {
  line-height: 24px;
}
.ant-drawer-header {
  height: 64px;
}
.ant-drawer-footer {
  height: 64px;
  display: flex;
  align-items: center;
}

