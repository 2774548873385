@import '../../colors.less';

/* ------------------------------- General CSS ------------------------------ */
.beans-upload {
  .ant-upload-list {
    margin-top: 8px;
  }

  .ant-upload-list > * {
    margin-bottom: 8px;
  }

  .ant-upload-list > *:last-child {
    margin-bottom: 0;
  }

  .ant-progress-inner {
    display: block;
  }
}

.ant-upload.ant-upload-drag.beans-upload-dragger {
  margin-bottom: 16px;
  background-color: @white;
  border-color: #e6e6e6;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &.ant-upload-disabled {
    opacity: 0.4;
  }

  &.beans-upload-dragger-error:not(.ant-upload-disabled),
  &.beans-upload-dragger-error:hover:not(.ant-upload-disabled) {
    border-color: @red-500;
    background-color: #fff5f5;

    * {
      color: @red-500;
    }
  }

  &:hover {
    border-color: #e6e6e6;
    background-color: @gray-100;
  }

  & + .ant-upload-list > * {
    margin-bottom: 8px;
  }

  & + .ant-upload-list > *:last-child {
    margin-bottom: 0;
  }

  & + .ant-upload-list .ant-progress-inner {
    display: block;
  }
}

.beans-upload-grid {
  & .ant-upload-list-text-container,
  & + .ant-upload-lis .ant-upload-list-text-container {
    margin: 0;
  }

  & .ant-upload-list,
  & + .ant-upload-list {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    grid-template-columns: repeat(6, 80px);
    grid-template-rows: auto;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

.beans-upload-status-icon {
  font-size: 16px;
}

/* ---------------------------- Upload List File ---------------------------- */
.beans-upload-list-file {
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  gap: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: @white;
  box-sizing: border-box;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  .beans-upload-status-icon.beans-upload-status-icon-remove {
    display: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: none;
  }

  &:focus:enabled:not(.beans-upload-list-file-error) {
    border-color: @beans-primary-color;
  }

  &:hover:enabled:not(.beans-upload-list-file-error) {
    background-color: @gray-100;
  }

  &:hover:enabled {
    .beans-upload-status-icon {
      display: none;
    }

    .beans-upload-status-icon.beans-upload-status-icon-remove {
      display: block;
    }
  }
}

.beans-upload-list-file-img {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  overflow: hidden;
}

.beans-upload-list-file-desc {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.beans-upload-list-file-text {
  text-align: left;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.beans-upload-list-file-title {
  font-weight: 600;
}

.beans-upload-list-file-size {
  color: @gray-600;
}

.beans-upload-list-file-error:enabled {
  border-color: @red-500;
  background-color: #fff5f5;

  svg,
  p {
    color: @red-500;
    fill: @red-500;
  }
}

/* ---------------------------- Base Upload Tile ---------------------------- */
.beans-upload-base-tile {
  position: relative;

  .beans-upload-status-icon.beans-upload-status-icon-remove {
    display: none;
  }

  &.beans-upload-base-tile-disabled {
    opacity: 0.4;

    .beans-upload-bease-tile-btn {
      cursor: not-allowed;
    }
  }

  .beans-upload-bease-tile-btn:focus-visible {
    outline: none;
  }

  &:not(.beans-upload-base-tile-disabled):not(.beans-upload-base-tile-error) .beans-upload-bease-tile-btn:focus {
    border-color: @beans-primary-color;
  }

  &:not(.beans-upload-base-tile-disabled):not(.beans-upload-base-tile-error) .beans-upload-bease-tile-btn:hover {
    background-color: @gray-100;
  }

  &:hover:not(.beans-upload-base-tile-disabled) {
    .beans-upload-status-icon {
      display: none;
    }

    .beans-upload-status-icon.beans-upload-status-icon-remove {
      display: block;
    }
  }
}

.beans-upload-bease-tile-btn {
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background-color: transparent;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.beans-upload-base-tile-status-wrapper {
  position: absolute;
  right: -6px;
  top: -6px;
}

.beans-upload-base-tile-error:not(.beans-upload-base-tile-disabled) {
  .beans-upload-bease-tile-btn {
    border-color: @red-500;
    background-color: #fff5f5;
  }

  p,
  svg {
    color: @red-500;
    fill: @red-500;
  }
}

/* ---------------------------- Logo Upload Tile ---------------------------- */
.beans-logo-upload-tile-container {
  display: inline-flex;
}

.beans-logo-upload-tile {
  width: 60px;
  height: 60px;

  .beans-upload-bease-tile-btn {
    padding: 9px;
  }
}

.beans-logo-upload-tile-desc {
  margin-left: 20px;
  padding: 6px 0;
}

.beans-logo-upload-tile-desc-text {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.beans-logo-upload-tile-desc-size {
  color: @gray-600;
}

/* ---------------------------- Upload Grid Tile ---------------------------- */
.beans-grid-upload-tile {
  width: 80px;
  height: 80px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

/* -------------------------------- Uploader -------------------------------- */
