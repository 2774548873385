@import '../../shadows.less';
@import '../../colors.less';

.beans-simple-dropdown-root {
  position: relative;
}

.beans-simple-dropdown {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: max-content;
  position: absolute;
  box-shadow: @beans-card-shadow;
  top: calc(100% + 12px);
  border-radius: 4px;
  left: 0;
  z-index: 1000;
  padding: 12px;
  min-width: 300px;
  background: @white;
  display: none;
}

.beans-simple-dropdown-open {
  display: block;
}
