@import '../../colors.less';

.beans-table-root {
  // table cell
  .ant-table-thead > tr > th,
  .ant-table-cell {
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
  }

  // table head
  .ant-table-thead > tr > th {
    background-color: transparent;
    text-align: left !important;
  }

  // active sorted column
  td.ant-table-column-sort {
    background-color: transparent;
  }

  // last row
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  // table checkbox cell
  table tr th.ant-table-selection-column,
  table tr td.ant-table-selection-column {
    padding: 16px;
  }

  // title
  .ant-table-title {
    border-bottom: 1px solid #f4f4f4;
  }

  // summary
  .ant-table-summary .ant-table-cell {
    border-top: 1px solid #f4f4f4;
    background-color: #fbfbfb;
  }

  // hover
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: #fbfbfb;
  }

  // hover sorted column
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #f4f4f4 !important;
  }
}

// table with checkbox
.beans-table-root.beans-table-root-checkbox {
  .ant-table-selection-column {
    position: relative;
  }

  .ant-table-selection {
    position: absolute;
    /*
      -4px - 34px = -38px
      -4px - move the checkbox to the edge of the header
      -34px - move the checkbox to the center of the area above the header
    */
    top: -38px;
  }

  .ant-table-selection-extra {
    padding-inline-start: 12px;
  }

  .ant-table-title {
    height: 54px !important;
    display: flex;
    align-items: center;
    padding: 11px 16px 11px 64px;
  }
}

// card
.beans-card-table {
  // overflow: hidden;

  .ant-card-body {
    padding: 0;
    border-top: 1px solid #f4f4f4;
  }

  .ant-card-body .beans-card-footer-body {
    padding: 0;
  }

  .ant-table-summary::after {
    content: '';
    display: block;
    height: 24px;
  }
}
