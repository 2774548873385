@import '../../colors.less';
@import '../../shadows.less';

.beans-phone-input {
  height: 32px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

/* ------------------------------- Main Input ------------------------------- */

.beans-phone-input .beans-phone-input--input {
  height: 32px;
  border-radius: 2px;
  border: 1px solid #e6e6e6;
  padding-left: 73px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;

  &::placeholder {
    color: @gray-500;
  }

  &:hover:not(:disabled) {
    border-color: #c4c4c4;
  }

  &:focus:not(:disabled) {
    border-color: @beans-primary-color;

    & + .beans-phone-input--button {
      border-color: @beans-primary-color;

      &:hover {
        border-color: @beans-primary-color !important;
      }
    }
  }

  &:disabled {
    opacity: 0.4;
    color: rgba(0, 0, 0, 0.25);

    & + .beans-phone-input--button {
      opacity: 0.4;

      &:hover,
      &:focus,
      &:focus-within {
        border-color: transparent !important;
      }

      .selected-flag:hover {
        background-color: @gray-100;
      }
    }
  }
}

/* ----------------------------- Dropdown Button ---------------------------- */

.beans-phone-input .beans-phone-input--button.beans-phone-input--button {
  width: 65px;
  border: 1px solid transparent;
  border-radius: 2px 0px 0px 2px !important;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    border-color: transparent !important;
  }

  &:focus,
  &:focus-within {
    border-color: @beans-primary-color !important;
  }

  .selected-flag {
    width: 100%;
    background-color: @gray-100;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px 0px 0px 1px !important;
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      background-color: #f4f4f4;
    }

    .flag {
      position: relative;
      left: -4px;
      top: auto;
      margin-top: 0;
    }
  }
}

/* --------------------------- Dropdown Container --------------------------- */

.beans-phone-input .beans-phone-input--dropdown {
  box-shadow: @beans-card-shadow;
  border-radius: 4px;
  padding: 8px;
  width: max-content;

  .divider {
    display: none;
  }

  .country {
    height: 34px;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;

    &.highlight {
      background-color: #f7fcff;
      border-color: #0075ff;
    }
  }
}

/* ------------------------------ Invalid Input ----------------------------- */
.beans-phone-input .beans-phone-input--input.invalid-number {
  background-color: transparent;
  border-color: #e6e6e6;
  color: @black;
}

.beans-phone-input .beans-phone-input--input.invalid-number:not(:disabled) {
  background-color: #fff5f5;
  border-color: @red-500;
  color: @red-500;

  &:hover {
    border-color: @red-500;
  }

  &:focus {
    border-color: @red-500;
    background-color: transparent;

    & + .beans-phone-input--button {
      border-color: @red-500;

      &:hover {
        border-color: @red-500 !important;
      }
    }
  }

  & + .beans-phone-input--button.beans-phone-input--button {
    &:focus,
    &:focus-within {
      border-color: @red-500 !important;
    }
  }
}
